
/* --------------------------------------------------------------- */
/* --- HTML TAGS ------------------------------------------------- */
/* --------------------------------------------------------------- */

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    border: none;
    max-width: 100%;
    height: auto;
}

a {
    color: #900;
    text-decoration: none;
    outline: none;
}

a:hover {
    text-decoration: none;
}

a[href^=tel] {
    color: inherit;
    text-decoration: none;
}


address {
    font-style:normal;
}


/* --------------------------------------------------------------- */
/* --- FONT  ----------------------------------------------------- */
/* --------------------------------------------------------------- */



body {
    font-family: Arial, sans-serif;
    font-size:14px;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing:0.04em;
    color:#232323;
}

ul {
    margin: 0 0 0 15px;
    padding: 0;
}

ol {
    margin: 0 0 0 25px;
    padding: 0;
}

p,
li {
    margin: 0 0 .6rem;
    padding: 0;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    line-height: 1.2;
    margin: 0;
}

h1 {
    font-size: 20px;
    margin: 0 0 10px 0;
    font-weight:700;
}

h2 {
    font-size: 14px;
    margin: 0 0 30px 0;
    font-weight:normal;
}

h3 {
    font-size: 36px;
    line-height:1.05;
    margin: 0 0 10px;
    font-weight:normal;
}

h4 {
    font-size: 1em;
    margin: 0 0 10px;
}

h5 {
    font-size: 1em;
    margin: 0 0 10px;
}

table tr th {
    font-weight: bold;
}

table tr td {

}

/* --------------------------------------------------------------- */
/* --- HAUPT AUFBAU ---------------------------------------------- */
/* --------------------------------------------------------------- */
body {
    background:#e2e2e2;
}

.box {
    width:90%;
    max-width:500px;
    max-height:100%;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background:#fff;
}

.box-inner {
    padding:20px;
    z-index:1;
    background:#fff;
}

.box:after,
.box:before {
    content:'';
    z-index:-1;
    width:200px;
    position:absolute;
    bottom:20px;
    left:10px;
    top:80%;
    box-shadow:0 20px 15px #777;
    transform:rotate(-5deg);
}

.box:after{
    left:auto;
    right:10px;
    transform:rotate(5deg);
}

.intro-text {
    margin-bottom:20px;
    color:#aaa;
}